import { createGlobalStyle } from 'styled-components';
import mq from '@mq';

const Vars = createGlobalStyle`
  :root {
    --color-black: #000;
    --color-white: #fff;
    --color-background: var(--color-white);
    --color-text: var(--color-black);

    --page-margin: 1.25rem; //20

    --font-family: 'medium', sans-serif;
    --font-regular: 1.25rem / 1.3 var(--font-family);
    
    ${mq.medium} {
      --font-regular: 1.5rem / 1.3 var(--font-family);
    }

    --motion: 200ms;
  }
`;

export default Vars;
