import { createGlobalStyle } from 'styled-components';

import { layoutWrapper } from '@mixins';
import { fontL, fontM, fontS, fontXL, fontXS, fontXXL, fontXXXL } from '@fonts';
import mq from '@mq';

const Base = createGlobalStyle`
  html,
  body {
    font-size: 16px;
    line-height: 1.25;
    background: var(--color-background);
    color: var(--color-text);
    font-family: var(--font-family);
    
    -webkit-font-smoothing: antialiased;
  }

  main {
    font: var(--font-regular);
  }
`;

export default Base;
